@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@800&family=Roboto:wght@300;400;700&display=swap');

/* Colors */
/* #4F5D75 */
/* 395756
1B3022 */

a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
.body {
  display: flex;
  flex-direction: column;
  gap: 80px;
  margin-top: 250px;
  padding-left: 50px;
  padding-right: 50px;
}
.body_section {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 50px;
  width: 100%;
}
.body_section_image {
  border-radius: 8px;
  overflow: hidden;
  width: '40%';
}
.body_section_text {
  color: #EE6C4D;
  font-family: 'Playfair Display', serif;
  font-size: 45pt;
  letter-spacing: 2px;
  margin-left: 50px;
  width: '60%';
}
.body_title {
  color: #EE6C4D;
  font-family: 'Playfair Display', serif;
  font-size: 45pt;
  letter-spacing: 2px;
  text-align: center;
}
.company_logo {
  height: 250px;
  margin-left: 40pt;
  width: 275px;
}
.container {
  background-color: #354E6E;
  padding-bottom: 70px;
}
.constructionImg {
  height: 800px;
  object-fit: contain;
  overflow: hidden;
  position: absolute;
  right: 50px;
  top: 50px;
  width: 500px;
}
.header {
  background-color: white;
  color: #354E6E;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  min-height: 60vh;
}
.header_title {
  color: #354E6E;
  font-family: 'Playfair Display', serif;
  font-size: 45pt;
  letter-spacing: 2px;
  margin-left: 50px;
}
.header_sub_title {
  color: #354E6E;
  font-family: 'Roboto', sans-serif;
  margin-left: 50px;
}
.pic {
  height: '100%';
  width: '100%';
}
.service {
  color: #ffffff;
  font-family: 'Playfair Display', serif;
  font-size: 22pt;
  letter-spacing: 2px;
  margin-bottom: 0px;
  text-align: center;
}
.header_image {
  height: 500px;
  right: 80px;
  position: absolute;
  top: 50;
  width: 500px;
}
.slanted-div {
  background: white;
  padding: 50px 0;
  position: relative;
  overflow: visible;
  z-index: 1;
}
.slanted-div:before, .slanted-div:after {
  background: inherit;
  content: '';
  height: 100%;
  position: absolute;
  transform: skewY(-3deg);
  transform-origin: right top;
  top: 0;
  width: 100%;
  z-index: -1;
}
.slanted-div:after {
  bottom: 0;
  transform: skewY(3deg);
  transform-origin: right bottom;
}
.why_us_container {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 25px;
  justify-content: space-evenly;
  width: '100%';
}
.why_us_icon {
  color: white;
  height: 125px;
  width: 125px;
}
.why_us_section {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.why_us_section_text {
  color: white;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 2pt;
  font-size: 14pt;
  font-weight: 700;
  margin-top: 5;
  text-align: center;
  white-space: pre;
}
@media screen and (max-width: 1140px) {
  .services_image {
    height: 220px;
    left: 100px;
    position: absolute;
    top: 250px;
    width: 220px;
  }
}
@media screen and (max-width: 990px) {
  .header_image {
    height: 250px;
    top: 250px;
    width: 250px;
  }
}
@media screen and (max-width: 650px) {
  .body {
    padding-left: 25px;
    padding-right: 25px;
  }
  .company_logo {
    height: 150px;
    width: 175px;
  }
  .slanted-div {
    padding: 25px 0;
  }
  .why_us_container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: '100%';
  }
}