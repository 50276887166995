.image_slider_container {
    max-width: 750px;
}
.image_slider_frame {
    border-color: #A6C9DE;
    border-style: solid;
    border-width: 5px;
    box-sizing: content-box;
    margin-right: 50px;
    margin-top: 20px;
    max-width: 750px;
    position: absolute;
}